<template>
  <v-card
    width="100%"
    flat
  >
    <v-row
      no-gutters
      :style="{ height: '12%' }"
    >
      <v-card-title :style="{ borderBottom: '1px solid #ccc', width: '100%', height: '60px', fontSize: '2.5vh' }">
        {{ title }}
      </v-card-title>
    </v-row>
    <v-row
      no-gutters
      :style="{ height: '88%' }"
      class="pt-2"
    >
      <v-col>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="5"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Obrigatório cliente na ordem de serviço:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="5"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Imprime protocolo na ordem de serviço:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="1"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>  
          <v-col 
            sm="12"
            md="10"
            lg="6"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="d-flex align-center "
          >
            Descrição padrão para ordem de serviço:            
          </v-col>
          <v-col
            lg="6"
          />        
          <v-col 
            sm="12"
            md="10"
            lg="6"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="d-flex align-center"
          >
            <v-textarea 
              name=""
              label=""
              background-color="amber lighten-4"
              value="Declaro ter recebido e conferido o produto."        
            />
          </v-col>
        </v-row>
        <v-divider
          horizontal
          class="pb-5"
        />
        <v-row
          no-gutters          
        >
          <v-col
            sm="6"
            md="10"
            lg="7"
            :cols="$vssWidth < 600 ? 3 : 8"
            class="d-flex align-center"
          >
            Fluxo:
          </v-col>
          <v-col 
            sm="1"
            md="1"
            lg="1"
            :cols="$vssWidth < 600 ? 3 : 8"
            class=""
          >
            <v-text-field
              label=""
              value=""             
              outlined
              dense
              flat        
              single-line   
              class="" 
            />
          </v-col>
          <v-col 
            sm="4"
            md="4"
            lg="3"
            :cols="$vssWidth < 600 ? 6 : 8"
            class="pl-2"
          >
            <v-text-field
              label=""
              value=""
              outlined
              dense
              flat        
              single-line
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="6"
            md="10"
            lg="7"
            :cols="$vssWidth < 600 ? 3 : 8"
            class="d-flex align-center"
          >
            Tarefa:
          </v-col>
          <v-col
            sm="5"
            md="4"
            lg="4"
            :cols="$vssWidth < 600 ? 9 : 8"
            class="pt-2"
          >
            <v-select
              dense
              outlined
              :items="['Envio para laboratório', 'Proto pra entrega', 'Entregue']"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {


    }
  },
  watch: {

  },
  mounted() {

  },
  methods: {

  },

}
</script>